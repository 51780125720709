import { Icon } from '@hometap/htco-components';

type SliderFormErrorProps = {
  error?: string;
};

const SliderFormError = ({ error = 'Unable to retrieve info' }: SliderFormErrorProps) => {
  return (
    <div className="align-center flex flex h-[24px] flex-row items-center gap-2 text-red-100">
      <Icon name={'icon-error'} className={'h-[20px] w-[20px]'} />
      <p className="m-0">{error}</p>
    </div>
  );
};

export default SliderFormError;
