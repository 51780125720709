import numeral from 'numeral';

export const currency = (num: string | number = 0, includeCents = false) => {
  const format = includeCents ? '$0,0.00' : '$0,0';
  return numeral(num).format(format);
};

export const currencyWithCents = (num: string | number = 0) => {
  return currency(num, true);
};

export const percentage = (num: string | number = 0, emptyValue = '-') => {
  if (!num) {
    return emptyValue;
  }
  if (typeof num === 'string') {
    return `${parseFloat(num).toFixed(3)}%`;
  }
  return `${num.toFixed(3)}%`;
};

export const formatDecimal = (num: string | number = 0, format: string) => {
  return numeral(num).format(format);
};
