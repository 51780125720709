import { SelectionSet, useForm, DetailBox, Loader } from '@hometap/htco-components';
import { TASK_STATUSES } from 'data/constants/taskStatuses';
import { useEffect, useMemo, useState } from 'react';
import { useUpdateSpecificTaskContent } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { useQuery } from '@apollo/client';
import useNewAppraisalOrder from 'apps/track-details/Appraisals/hooks/useNewAppraisalOrder';
import {
  GET_REGGORA_ORDER_BY_ID,
  GET_REGGORA_PRODUCTS,
  GET_TRADITIONAL_APPRAISAL_XML_DATA,
} from 'apps/track-details/ApplicationReview/sections/HomeValuationsController/reggoraRequests';
import { reviewAppraisalTaskAction } from './constants';
import type { TaskNode } from '../../types/types';
import AppraisalOrderForm from 'apps/track-details/Appraisals/components/SliderForms/forms/AppraisalOrderForm';
import RevisionRequestForm from 'apps/track-details/Appraisals/components/SliderForms/forms/RevisionRequestForm';
import { formatDayOfWeekMonthDayYear } from 'utils/dates';
import SliderFormField from 'apps/track-details/Appraisals/components/SliderForms/common/SliderFormField';
import { currency } from 'utils/numbers';
import SliderFormText from 'apps/track-details/Appraisals/components/SliderForms/common/SliderFormText';
import './ReviewAppraisal.scss';
import SliderFormError from 'apps/track-details/Appraisals/components/SliderForms/common/SliderFormError';

const MUTABLE_DEFAULT_FORM_VALUES = {
  revisionTitle: '',
  revisionReason: '',
  selectedOption: null,
  rush: false,
  products: null,
};

const FETCHED_DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
};

enum APPRAISAL_TASK_DEFINITION_KEYS {
  VIRTUAL = 'review-virtual-appraisal-task',
  TRADITIONAL = 'review-traditional-appraisal-task',
}

const DEFAULT_FORM_DATA_VALUES = {
  ...MUTABLE_DEFAULT_FORM_VALUES,
  ...FETCHED_DEFAULT_VALUES,
};

const ReviewAppraisal = ({ task }: { task: TaskNode }) => {
  const isCompleted = task.taskStatus === TASK_STATUSES.COMPLETED;
  const isVirtual = task?.taskDefinitionKey === APPRAISAL_TASK_DEFINITION_KEYS.VIRTUAL;
  const isTraditional = task?.taskDefinitionKey === APPRAISAL_TASK_DEFINITION_KEYS.TRADITIONAL;
  const [appraisalType, setAppraisalType] = useState<string | null>(null);
  const { registerField, setErrors, updateFormData, formErrors, formData } = useForm(DEFAULT_FORM_DATA_VALUES);

  const variables = task?.variables;
  const trackId = variables?.find(variable => variable.name === 'track_id')?.value?.replaceAll('"', '') || '';
  const orderId = variables?.find(variable => variable.name === 'order_id')?.value?.replaceAll('"', '') || '';

  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(task.identifier);
  const { prefilledOrderValues, trackDetailsForReggoraOrderDataLoading: formPrefillDetailsLoading } =
    useNewAppraisalOrder(trackId);

  const { data: reggoraProductData, loading: isProductsLoading } = useQuery(GET_REGGORA_PRODUCTS, {
    skip: formData?.selectedOption !== reviewAppraisalTaskAction.FOLLOW_UP,
  });

  const {
    loading: reggoraOrderXmlDataLoading,
    error: reggoraOrderXmlDataError,
    data: reggoraAppraisalXMLData,
  } = useQuery(GET_TRADITIONAL_APPRAISAL_XML_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      orderId,
    },
    skip: !orderId,
    notifyOnNetworkStatusChange: true,
  });

  const { loading: selectedReggoraOrderLoading, error: selectedReggoraOrderError } = useQuery(GET_REGGORA_ORDER_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      orderId: orderId,
      trackId: trackId,
    },
    skip: !isTraditional || !orderId,
    notifyOnNetworkStatusChange: true,
    onCompleted: selectedReggoraOrder => {
      const { product } = selectedReggoraOrder?.order || {};
      if (product) {
        setAppraisalType(product);
      }
    },
    onError: _err => {
      setAppraisalType(null);
    },
  });

  const reggoraProductOptions = reggoraProductData?.reggoraProducts?.products || [];

  const loading =
    Boolean(formPrefillDetailsLoading) ||
    Boolean(isProductsLoading) ||
    Boolean(reggoraOrderXmlDataLoading) ||
    Boolean(selectedReggoraOrderLoading);

  // Needed in order to pre-populate form data correctly
  useEffect(() => {
    updateFormData({
      firstName: prefilledOrderValues?.primaryApplicant?.firstName || '',
      lastName: prefilledOrderValues?.primaryApplicant?.lastName || '',
      emailAddress: prefilledOrderValues?.primaryApplicant?.person?.email || '',
      phoneNumber: prefilledOrderValues?.primaryApplicant?.phoneNumber || '',
    });
  }, [prefilledOrderValues, updateFormData]);

  const isFormValid = useMemo(() => {
    switch (formData?.selectedOption) {
      case reviewAppraisalTaskAction.ACCEPTED:
        return true;
      case reviewAppraisalTaskAction.REVISION:
        return formData?.revisionTitle && formData?.revisionTitle;
      case reviewAppraisalTaskAction.FOLLOW_UP:
        return formData?.products && formData?.products.length > 0;
      case reviewAppraisalTaskAction.DQ:
        return true;
      default:
        return false;
    }
  }, [formData?.products, formData?.revisionTitle, formData?.selectedOption]);

  useEffect(() => {
    updateSpecificTaskById(task.identifier, {
      formData,
      updateFormData,
      isValidForm: isFormValid,
      saving: loading,
    });
  }, [updateSpecificTaskById, formData, isFormValid, task.identifier, updateFormData, loading]);

  const appraisalActionOptions = [
    {
      label: 'Appraisal approved',
      value: reviewAppraisalTaskAction.ACCEPTED,
    },
    {
      label: 'Needs revision',
      value: reviewAppraisalTaskAction.REVISION,
    },
    ...(isVirtual
      ? [
          {
            label: 'Follow-up order',
            value: reviewAppraisalTaskAction.FOLLOW_UP,
          },
        ]
      : []),
    {
      label: 'DQ',
      value: reviewAppraisalTaskAction.DQ,
    },
  ];

  const traditionalAppraisalFetchHasError = Boolean(selectedReggoraOrderError) || Boolean(reggoraOrderXmlDataError);

  const callOutText = useMemo(() => {
    switch (formData?.selectedOption) {
      case reviewAppraisalTaskAction.ACCEPTED:
        if (isTraditional) {
          return {
            text: 'A new home valuation will be created and marked as the relevant home value when this task is marked as complete.',
          };
        }
        return {
          text: 'The most recent AVM will be marked as the relevant home value when this task is marked as complete.',
        };
      case reviewAppraisalTaskAction.REVISION:
        return { text: 'Revision request will be sent when this task is marked as complete.' };
      case reviewAppraisalTaskAction.FOLLOW_UP:
        return { text: 'Follow-up order will be placed when this task is marked as complete.' };
      case reviewAppraisalTaskAction.DQ:
        return {
          text: 'Please coordinate with your IM. ',
          className: 'ErrorContainer',
          icon: 'alert',
        };
      default:
        return null;
    }
  }, [formData?.selectedOption, isTraditional]);

  const customProductOnBlur = () => {
    if (formErrors?.products) {
      setErrors('products', formErrors?.products?.message, true);
    }
  };

  const {
    appraiserName,
    asOfDate,
    certificationNumber,
    scheduledDate: scheduledFor,
    value: homeValue,
  } = reggoraAppraisalXMLData?.traditionalAppraisalXmlData || {};

  const genericFieldOrError = (field: string | null, error: string, hasError: boolean) => {
    if (hasError) {
      return <SliderFormError error={error} />;
    } else if (field) {
      return <SliderFormText text={field} />;
    }
    // loading state, or state where data is not returned but an error is not present
    return <></>;
  };

  return (
    <div className="flex flex-col space-y-4">
      <SelectionSet
        name="example"
        label={
          <span>
            Review the appraisal report to make sure all the necessary criteria are met.{' '}
            <a href="https://app.getguru.com/card/iExGGMxT/Ordering-Appraisals-and-Reviewing-Appraisal-Status-in-the-Hub-App-Specialist-Procedure">
              Reference this Guru card.
            </a>
          </span>
        }
        labelWidth="100%"
        align="left"
        stacked={false}
        multiple={false}
        disabled={isCompleted}
        options={appraisalActionOptions}
        className="SelectorElement"
        labelGrow={true}
        {...registerField('selectedOption')}
        // every time the selected option changes, reset the form data
        onChange={(selectedOption: string) => {
          updateFormData({
            ...MUTABLE_DEFAULT_FORM_VALUES,
            selectedOption,
          });
        }}
      />

      {callOutText && (
        <DetailBox
          text={callOutText?.text}
          theme={'bright'}
          icon={callOutText?.icon}
          className={`ReviewAppraisalCalloutContainer ${callOutText?.className}`}
        />
      )}

      {formData?.selectedOption === reviewAppraisalTaskAction.ACCEPTED && isTraditional && (
        <>
          {loading ? (
            <div className="ReviewAppraisalLoader align-center align-center flex flex-row justify-center">
              <Loader size={'large'} type={'dot-pulse'} className="py-12" />
            </div>
          ) : (
            <div className="traditionalAppraisalDataContainer flex flex-col gap-4">
              {traditionalAppraisalFetchHasError ? (
                <DetailBox
                  text={
                    "We're having trouble fetching the appraisal data. Please try again or reach out to report the issue."
                  }
                  theme={'bright'}
                  icon={'alert'}
                  className={`ReviewAppraisalCalloutContainer ErrorContainer`}
                />
              ) : (
                <>
                  <div className="text-lg font-bold text-neutral-dark-100">
                    New traditional appraisal home valuation
                  </div>
                  <SliderFormField
                    label="Appraisal type"
                    field={genericFieldOrError(appraisalType, 'Unable to fetch appraisal type', !appraisalType)}
                  />
                  <SliderFormField
                    label="Scheduled for"
                    field={genericFieldOrError(
                      formatDayOfWeekMonthDayYear(scheduledFor),
                      'Unable to fetch scheduled date',
                      !scheduledFor,
                    )}
                  />
                  <SliderFormField
                    label="Home value"
                    field={genericFieldOrError(currency(homeValue), 'Unable to fetch home value', !homeValue)}
                  />
                  <SliderFormField
                    label="Appraiser name"
                    field={genericFieldOrError(appraiserName, 'Unable to fetch Appraiser name', !appraiserName)}
                  />
                  <SliderFormField
                    label="Certification number"
                    field={genericFieldOrError(
                      certificationNumber,
                      'Unable to fetch certification number',
                      !certificationNumber,
                    )}
                  />
                  <SliderFormField
                    label="As of date"
                    field={genericFieldOrError(
                      formatDayOfWeekMonthDayYear(asOfDate),
                      'Unable to fetch as of date',
                      !asOfDate,
                    )}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}

      {formData?.selectedOption === reviewAppraisalTaskAction.REVISION && (
        <>
          <span className="text-lg font-bold">Revision Request</span>
          <RevisionRequestForm
            registerField={registerField}
            theme="outlined"
            revisionNotesName="revisionTitle"
            revisionFieldName="revisionReason"
          />
        </>
      )}

      {formData?.selectedOption === reviewAppraisalTaskAction.FOLLOW_UP &&
        !formPrefillDetailsLoading &&
        prefilledOrderValues && (
          <AppraisalOrderForm
            loading={loading}
            isFollowUp={true}
            prefilledOrderValues={prefilledOrderValues}
            productOptions={reggoraProductOptions}
            formErrors={formErrors}
            registerField={registerField}
            productOnBlur={customProductOnBlur}
            formData={formData}
            updateFormData={updateFormData}
          />
        )}
    </div>
  );
};

export const REVIEW_APPRAISAL_DEMO_DATA = {
  selectedOption: reviewAppraisalTaskAction.ACCEPTED,
};

export default ReviewAppraisal;
