import { currencyWithCents, percentage } from 'utils/numbers';
import {
  upshareErrorFromCriteria,
  homeownerEndingEquityErrorFromCriteria,
  totalEndingEquityErrorFromCriteria,
  investmentAmountErrorFromCriteria,
  totalPaydownExceedsErrorFromCriteria,
  rentalCltvTooHighErrorFromCriteria,
} from './calculatorErrors';
import { isInUnderwriterGroup } from 'userRoles';
import { isInInvestmentGuideCalculatorRolloutGroup } from 'userRolloutGroups';

export const getOutputFields = (user, calculatedData) => {
  const uwFailedEligibilityCriteria = calculatedData?.uwFailedEligibilityCriteria;

  const totalEndingEquityError = totalEndingEquityErrorFromCriteria(uwFailedEligibilityCriteria);
  let totalEndingEquityErrorMessage = null;
  if (totalEndingEquityError) {
    totalEndingEquityErrorMessage = `${totalEndingEquityError}.`;
    if (calculatedData?.increaseInPaydown > 0) {
      totalEndingEquityErrorMessage = `${totalEndingEquityError}. Additional paydown needed.`;
    }
  }

  const outputFields = [
    {
      label: 'Max Investment Amount',
      value: currencyWithCents(calculatedData?.investmentAmount),
      error: investmentAmountErrorFromCriteria(uwFailedEligibilityCriteria),
    },
    {
      label: 'Total Paydown',
      value: currencyWithCents(calculatedData?.assumedTotalPaydown),
      error: totalPaydownExceedsErrorFromCriteria(uwFailedEligibilityCriteria),
    },
    {
      label: ['To Homeowner (', <i key="toHomeowner">before fees</i>, ')'],
      value: currencyWithCents(calculatedData?.toHomeownerBeforeFees),
    },
    {
      label: 'Hometap Up Share',
      value: percentage(calculatedData?.hometapUpshare * 100),
      error: upshareErrorFromCriteria(uwFailedEligibilityCriteria),
      permissions: isInUnderwriterGroup(user),
    },
    {
      label: 'Hometap Down Share',
      value: percentage(calculatedData?.hometapDownshare * 100),
      permissions: isInUnderwriterGroup(user),
    },
    {
      label: 'Homeowner Ending Equity',
      value: percentage(calculatedData?.homeownerEndingEquity * 100),
      error: homeownerEndingEquityErrorFromCriteria(uwFailedEligibilityCriteria),
      permissions: !isInInvestmentGuideCalculatorRolloutGroup(user),
    },
    {
      error: totalEndingEquityErrorMessage,
    },
    {
      label: 'Loss Threshold',
      value: percentage(calculatedData?.lossThreshold * 100),
      permissions: isInUnderwriterGroup(user),
    },
    {
      label: 'Max CLTV',
      value: percentage(calculatedData?.maxCombinedLoanToValue * 100),
      error: rentalCltvTooHighErrorFromCriteria(uwFailedEligibilityCriteria),
      permissions: isInUnderwriterGroup(user),
    },
  ];

  return outputFields.filter(({ permissions }) => permissions !== false);
};
